import React from 'react';

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-grey-light has-text-black has-margin-bot-1">
        <div className="content has-text-centered">
          <p>
            Copyright © Prime Time Management Sàrl {new Date().getFullYear()}
          </p>
        </div>
      </footer>
    );
  }
};

export default Footer;
